import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
    ChangePassword,
    Dashboard,
    MyProfile,
    ResetPassword,
    SignUp,
    LogIn,
    SubscriptionPlan,
    PageNotFound,
    StockDetailsAddEdit,
    ForgotPassword,
    Success,
    CancelPayment,
    Home,
} from 'pages'
import { AuthLayout, LandingLayout, MainLayout } from 'Layout'

function Router() {
    return (
        <Routes>
            <Route path='/auth' element={<AuthLayout />}>
                <Route path='/auth' element={<Navigate to='signin' />} />
                <Route path='signin' element={<LogIn />} />
                <Route path='signup' element={<SignUp />} />
                <Route path='reset-password' element={<ResetPassword />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                <Route
                    path='subscription-plan'
                    element={<SubscriptionPlan />}
                />
            </Route>

            {/* Main layouts */}
            <Route path='/protrade' element={<MainLayout />}>
                <Route
                    path='/protrade'
                    element={<Navigate to='/protrade/dashboard' />}
                />
                <Route path='dashboard' element={<Dashboard />} />
                <Route
                    path='dashboard/stock/:id'
                    element={<StockDetailsAddEdit />}
                />
                <Route path='profile' element={<MyProfile />} />
                <Route path='change-password' element={<ChangePassword />} />
                <Route path='*' element={<PageNotFound />} />
            </Route>

            {/* Old code without landing layouts  */}
            {/* <Route path='/' element={<MainLayout />}>
                <Route path='/' element={<Navigate to='/dashboard' />} />
                <Route path='dashboard' element={<Dashboard />} />
                <Route
                    path='dashboard/stock/:id'
                    element={<StockDetailsAddEdit />}
                />
                <Route path='profile' element={<MyProfile />} />
                <Route path='change-password' element={<ChangePassword />} />
                <Route path='*' element={<PageNotFound />} />
            </Route> */}


            {/* landing layouts */}
            <Route path='/' element={<LandingLayout />}>
                <Route path='/' element={<Home />} />

                <Route path='*' element={<PageNotFound />} />
            </Route>
            <Route path='/success' element={<Success />} />
            <Route path='/cancel' element={<CancelPayment />} />
            <Route path='*' element={<PageNotFound />} />
        </Routes>
    )
}

export default Router
