import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { errorMessage } from 'constant/const'
import axiosInstance from 'utils/axiosConfig'
import { toastError, toastSuccess } from 'utils/toaster'

const initialState = {
    portfolioData: null,
    loading: false,
    addEditportfolio: false,
    portfolio: null,
    strategyList: null,
    searchPortfolio: null,
    addportfolio: false,
    deletePortfolio: false,
    selectedTab: 'open',
}

export const getPortfolioAction = createAsyncThunk(
    'dashboard/getPortfolioAction',
    async (data) => {
        try {
            const response = await axiosInstance.get(
                `/portfolio/get/?account_id=${data}`
            )
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)
export const updatePortfolioAction = createAsyncThunk(
    'dashboard/updatePortfolioAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/portfolio/update/?portfolio_id=${data.id}`,
                data.formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data;' },
                }
            )
            toastSuccess(response?.data?.message)
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const getPortfolioDetailsAction = createAsyncThunk(
    'dashboard/getPortfolioDetailsAction',
    async (data) => {
        try {
            const response = await axiosInstance.get(
                `/portfolio/detail/?portfolio_id=${data.id}&start_date=${
                    data?.start_date || ''
                }&end_date=${data?.end_date || ''}&chart=${data.chart || ''}`
            )
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const getStrategyListAction = createAsyncThunk(
    'dashboard/getStrategyListAction',
    async () => {
        try {
            const response = await axiosInstance.get(
                `/portfolio/strategy/list/`
            )
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const getsearchPortfolioAction = createAsyncThunk(
    'dashboard/getsearchPortfolioAction',
    async (data) => {
        try {
            const response = await axiosInstance.get(
                `/portfolio/search/?search_query=${
                    data?.search_query || ''
                }&exchange_code=${data?.exchange_code || ''}`
            )
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const addPortfolioAction = createAsyncThunk(
    'dashboard/addPortfolioAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `portfolio/add_stock/`,
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data;' },
                }
            )
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const deletePortfolioAction = createAsyncThunk(
    'dashboard/deletePortfolioAction',
    async (data) => {
        try {
            const response = await axiosInstance.get(
                `portfolio/delete/?portfolio_id=${data}`
            )
            toastSuccess(response?.data?.message)
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        resetSearchPortfolio: (state) => {
            state.searchPortfolio = null
        },
        onSeletedtTabAction: (state, action) => {
            state.selectedTab = action.payload
        },
        resteAddportfolio: (state) => {
            state.addportfolio = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPortfolioAction.pending, (state) => {
                state.loading = true
                state.portfolioData = null
                state.addEditportfolio = false
                state.portfolio = null
                state.deletePortfolio = false
            })
            .addCase(getPortfolioAction.fulfilled, (state, action) => {
                state.loading = false
                state.portfolioData = action.payload
                state.addEditportfolio = false
                state.portfolio = null
                state.deletePortfolio = false
            })
            .addCase(getPortfolioAction.rejected, (state) => {
                state.loading = false
                state.portfolioData = null
                state.deletePortfolio = false
            })
            .addCase(updatePortfolioAction.pending, (state) => {
                state.loading = true
                state.addEditportfolio = false
                state.portfolio = null
                state.addportfolio = false
            })
            .addCase(updatePortfolioAction.fulfilled, (state, action) => {
                state.loading = false
                state.addEditportfolio = true
                state.portfolio = action.payload
                state.addportfolio = false
            })
            .addCase(updatePortfolioAction.rejected, (state) => {
                state.loading = false
                state.addEditportfolio = false
                state.portfolio = null
                state.addportfolio = false
            })
            .addCase(getPortfolioDetailsAction.pending, (state) => {
                state.loading = true
                state.portfolio = null
                state.addEditportfolio = false
            })
            .addCase(getPortfolioDetailsAction.fulfilled, (state, action) => {
                state.loading = false
                state.portfolio = action.payload
            })
            .addCase(getPortfolioDetailsAction.rejected, (state) => {
                state.loading = false
                state.portfolio = null
            })
            .addCase(getStrategyListAction.pending, (state) => {
                state.loading = true
                state.strategyList = null
            })
            .addCase(getStrategyListAction.fulfilled, (state, action) => {
                state.loading = false
                state.strategyList = action.payload
            })
            .addCase(getStrategyListAction.rejected, (state) => {
                state.loading = false
                state.strategyList = null
            })
            .addCase(getsearchPortfolioAction.pending, (state) => {
                state.loading = true
                state.searchPortfolio = null
            })
            .addCase(getsearchPortfolioAction.fulfilled, (state, action) => {
                state.loading = false
                state.searchPortfolio = action.payload
            })
            .addCase(getsearchPortfolioAction.rejected, (state) => {
                state.loading = false
                state.searchPortfolio = null
            })
            .addCase(addPortfolioAction.pending, (state) => {
                state.loading = true
                state.portfolioData = null
                state.addportfolio = false
            })
            .addCase(addPortfolioAction.fulfilled, (state, action) => {
                state.loading = false
                state.portfolioData = action.payload
                state.addportfolio = true
            })
            .addCase(addPortfolioAction.rejected, (state) => {
                state.loading = false
                state.addportfolio = false
            })
            .addCase(deletePortfolioAction.pending, (state) => {
                state.loading = true
                state.portfolioData = null
                state.deletePortfolio = false
                state.addportfolio = false
            })
            .addCase(deletePortfolioAction.fulfilled, (state) => {
                state.loading = false
                state.deletePortfolio = true
                state.portfolioData = null
                state.addportfolio = false
            })
            .addCase(deletePortfolioAction.rejected, (state) => {
                state.loading = false
                state.portfolioData = null
                state.deletePortfolio = false
                state.addportfolio = false
            })
    },
})
export const { resetSearchPortfolio, onSeletedtTabAction, resteAddportfolio } =
    DashboardSlice.actions
export default DashboardSlice.reducer
