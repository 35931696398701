import React from 'react'

function Footor() {
    
    return (
        <footer className='main-footer'>
            <div className='footer-left'>
                <span>© 2024 ProTrade Plus Inc. All rights reserved</span>
            </div>
            <div className='footer-right'>
                <span>Terms of Use</span>
                <span className='privacy-text'> Privacy Policy</span>
            </div>
        </footer>
    )
}

export default Footor
