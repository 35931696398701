import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import './phoneInput.css'

function PhoneNumberInputBox(props) {
    const { label, name, rules, className, validateMobileCountry, ...rest } = props
    const { control } = useFormContext()
    return (
        <div className='form-group'>
            <label className='text-capitalize lable-wrap'>
                {label}
                {rules?.required && <span className='text-danger '>*</span>}
            </label>
            <Controller
                name={name}
                control={control}
                rules={{
                    rules,
                    validate: (value) => {
                        if (validateMobileCountry && value) {
                            let re = validateMobileCountry(value)
                            if (re) {
                                return isValidPhoneNumber(value)
                            } else {
                                return "Mobile number should belong to the selected country"
                            }
                        } else {
                            return value ? isValidPhoneNumber(value) : false
                        }
                    },
                }}
                render={({ field }) => (
                    <PhoneInput
                        international
                        numberInputProps={{
                            className: `form-control phone-input ${className}`,
                        }}
                        {...field}
                        {...rest}
                    />
                )}
            />
        </div>
    )
}

export default PhoneNumberInputBox
