import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    mobileSilder: false,
    mobileTitle: '',
}

const ThemeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleSlider(state, action) {
            state.mobileSilder = action.payload
        },
        setMobileTitle(state, action) {
            state.mobileTitle = action.payload
        },
    },
})

export const { toggleSlider, setMobileTitle } = ThemeSlice.actions
export default ThemeSlice.reducer
