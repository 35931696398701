import React from 'react'
import './home.css'
import { Button, Container } from 'react-bootstrap'
import StockTable from './components/StockTable'
import LongTermSection from './components/LongTermSection'
import WhyProtradePlus from './components/WhyProtradePlus'
import EasySignUpProcess from './components/EasySignUpProcess'
import FinancialFuture from './components/FinancialFuture'
import TransparentPricing from './components/TransparentPricing'

function Home() {
    return (
        <div className='home-page-section'>
            <div className='rising-tides-section'>
                <Container>
                    <div>
                        <h1 className='heading-9'>
                            Simplify trading decisions and maximize your profits
                            with Protrade+
                        </h1>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-lg-6 m-auto'>
                            <div className='home-page-content'>
                                <div className='with-protrade'>
                                    <p>
                                        With Protrade+, the BUY or SELL dilemma
                                        is a thing of the past.
                                    </p>
                                </div>
                            </div>
                            <p
                                style={{
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                    fontSize: '28px',
                                }}
                            >
                                If you had Protrade+ by your side over the last
                                year, here's a snapshot of the success you could
                                have enjoyed.
                            </p>
                            <div className='text-center'>
                                <Button
                                    className='btn btn-get-started'
                                    onClick={() =>
                                        window.open(
                                            '/auth/signup',
                                            '_blank'
                                        )
                                    }
                                >
                                    Sign Up Now
                                </Button>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 stock-table-show'>
                            <StockTable />
                        </div>
                    </div>
                </Container>
            </div>
            <Container>
                <LongTermSection />
                <WhyProtradePlus />
                <EasySignUpProcess />
                <TransparentPricing />
            </Container>
            <FinancialFuture />
        </div>
    )
}

export default Home
