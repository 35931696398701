import {
    FillPasswordIcon,
    FillPortfolioIcon,
    FillProfileIcon,
    PasswordIcon,
    PortfolioIcon,
    ProfileIcon,
} from 'assets'

export const menuList = [
    {
        name: 'Portfolio',
        icon: <PortfolioIcon />,
        activeIcon: <FillPortfolioIcon />,
        path: '/protrade/',
        activeName: 'dashboard',
    },
    {
        name: 'My Profile',
        icon: <ProfileIcon />,
        activeIcon: <FillProfileIcon />,
        path: '/protrade/profile',
        activeName: 'profile',
        isChildren: false,
    },
    {
        name: 'Change Password',
        icon: <PasswordIcon />,
        activeIcon: <FillPasswordIcon />,
        path: '/protrade/change-password',
        activeName: 'change-password',
        isChildren: false,
    },
    
]
