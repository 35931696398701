import { LogoWhiteImage } from 'assets'
import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer>
            <Container>
                <div className='footer-wrap'>
                    <div className='footer-first-section'>
                        <div className='footer-social-block-three'>
                            <a
                                href='#'
                                className='footer-social-link-three w-inline-block'
                                title='facebook'
                            >
                                <i className='fa-brands fa-square-facebook'></i>
                            </a>
                            <a
                                href='#'
                                className='footer-social-link-three w-inline-block'
                                title='twitter'
                            >
                                <i className='fa-brands fa-square-twitter'></i>
                            </a>
                            <a
                                href='#'
                                className='footer-social-link-three w-inline-block'
                                title='instagram'
                            >
                                <i className='fa-brands fa-square-instagram'></i>
                            </a>
                            <a
                                href='#'
                                className='footer-social-link-three w-inline-block'
                                title='youtube'
                            >
                                <i className='fa-brands fa-square-youtube'></i>
                            </a>
                        </div>
                    </div>
                    <div className='footer-second-section'>
                        <p>
                            Protrade+™ is not a financial advisor and does not
                            provide financial advice or services. We provide
                            real time notification services. Stock trading is
                            risky, and you could lose all your money. Consult a
                            financial advisor before engaging in stock trading.
                        </p>
                    </div>
                    <div className='footer-third-section'>
                        <div className='protrde-logo'>
                            <img
                                src={LogoWhiteImage}
                                alt='protrde-logo'
                                style={{ maxWidth: '100%', width: '160px' }}
                            />
                        </div>
                        <div className='terms-policy-div'>
                            <span>
                                © 2024 Protrade+ Inc. All rights reserved
                            </span>
                            <div className='term-wrap-div'>
                                <Link to={'#'}>Terms Of Use</Link>
                                <Link to={'#'}>Privacy Policy</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
