import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { LogoWhiteImage } from '../../assets'
import './authLayout.css'
import { useDispatch, useSelector } from 'react-redux'
import { proTradePlus } from 'constant/const'
import { resetUserInfo } from 'store/action/AuthRedux'

function AuthLayout() {
    const authData = useSelector((state) => state.auth)
    const { isLogin } = authData

    const dispatch = useDispatch()
    useEffect(() => {
        let users = localStorage.getItem(proTradePlus)
        let userData = JSON.parse(users)
        if (userData && !userData?.isLogin) {
            dispatch(resetUserInfo())
            localStorage.removeItem(proTradePlus)
        }
        return () => {}
    }, [isLogin])
    return (
        <div className='auth-layout'>
            <div className='row'>
                <div className='col-xl-4 col-12'>
                    <div className='auth-layout-right-panel'>
                        <div className='auth-gradient'>
                            <div className='logo-section'>
                                <img
                                    src={LogoWhiteImage}
                                    alt='LogoWhiteImage'
                                />
                            </div>

                            <div className='auth-info-section'>
                                <h3>Rising Tides Lift All Boats</h3>
                                <p>
                                    Simplify trading decisions and maximize your
                                    profits <br /> with ProTrade+
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xl-8 col-12'>
                    <div className='auth-common'>
                        <Outlet />
                        <div className='auth-footer'>
                            <span>
                                © 2024 ProTrade Plus Inc. All rights reserved
                            </span>
                            <div className='auth-footor-privecy-section'>
                                <span>Terms of Use</span>
                                <span className='auth-privacy'>
                                    Privacy Policy
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayout
