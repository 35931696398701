import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { errorMessage } from 'constant/const'
import axiosInstance from 'utils/axiosConfig'
import { toastError, toastSuccess } from 'utils/toaster'

const initialState = {
    loading: false,
    passwordUpdateStatus: false,
}

export const changePasswordAction = createAsyncThunk(
    'changePassword/changePasswordAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/user/account/change_password/`,
                data
            )
            toastSuccess(response?.data?.message)
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

const ChangePasswordSlice = createSlice({
    name: 'changePassword',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(changePasswordAction.pending, (state) => {
                state.loading = true
                state.passwordUpdateStatus = false
            })
            .addCase(changePasswordAction.fulfilled, (state) => {
                state.loading = false
                state.passwordUpdateStatus = true
            })
            .addCase(changePasswordAction.rejected, (state) => {
                state.loading = false
                state.passwordUpdateStatus = false
            })
    },
})

export default ChangePasswordSlice.reducer
