import React, { useEffect, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import axiosInstance from 'utils/axiosConfig'
import { toastError } from 'utils/toaster'
import { errorMessage } from 'constant/const'
import { convertObjectToFormData } from 'utils/convertFormData'
import { socialLoginAction } from 'store/action/AuthRedux'

function useSocialLoginHook() {
    const [authUser, setAuthUser] = useState(null)
    const dispacth = useDispatch()

    const googleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            setAuthUser(tokenResponse)
        },
        onNonOAuthError: (error) => {
            console.log('onNonOAuthError', error.message)
            toastError(error.message)
        },
        onError: (err) => {
            console.log('google login error', err.error)
            toastError(err.error || errorMessage)
        },
    })

    useEffect(() => {
        if (authUser && authUser?.access_token) {
            axiosInstance
                .get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${authUser?.access_token}`
                )
                .then((res) => {
                    if (res?.data) {
                        let uname = res?.data?.email
                            ? res?.data?.email.split('@')[0]
                            : ''
                        let obj = {
                            social_id: res?.data?.id,
                            username: uname,
                            email: res?.data?.email,
                        }
                        let data = convertObjectToFormData(obj)
                        dispacth(socialLoginAction(data))
                    }
                })
                .catch((err) => {
                    toastError(errorMessage)
                })
        }
        return () => { }
    }, [authUser])

    return { googleLogin }
}

export default useSocialLoginHook
