import {
    ChangesPasswordIcon,
    PassworHidedIcon,
    PasswordIcon,
    PasswordShowIcon,
} from 'assets'
import { Card, Loader } from 'components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMobileTitle } from 'store/action/ThemeRedux'
import './changePassword.css'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { passwordErrorMessage } from 'constant/const'
import { Button } from 'react-bootstrap'
import { toastError } from 'utils/toaster'
import { convertObjectToFormData } from 'utils/convertFormData'
import { changePasswordAction } from 'store/action/ChangePasswordRedux'
import { ErrorMessage } from '@hookform/error-message'

function ChangePassword() {
    const dispatch = useDispatch()
    const changePasswordRedux = useSelector((state) => state.changePassword)
    const { loading, passwordUpdateStatus } = changePasswordRedux

    const methods = useForm()
    const { handleSubmit, reset } = methods

    useEffect(() => {
        dispatch(setMobileTitle('Change Password'))
        return () => { }
    }, [])

    useEffect(() => {
        if (passwordUpdateStatus) {
            reset()
        }
        return () => { }
    }, [passwordUpdateStatus])

    const onSubmit = (data) => {
        if (data.new_password === data.confirm_passwrod) {
            let formData = convertObjectToFormData(data)
            dispatch(changePasswordAction(formData))
        } else {
            toastError('Password not match, please check your new password')
        }
    }
    return (
        <div className='dashboard-wrap pb-5'>
            {loading && <Loader />}
            <Card>
                <div className='change-password-wrap'>
                    <div className='row'>
                        <div className='col-12 col-xl-6'>
                            <ChangesPasswordIcon className='changes-pwd-icon' />
                        </div>
                        <div className='col-12 col-xl-6'>
                            <FormProvider {...methods}>
                                <form>
                                    <div className='changes-pwd-form row'>
                                        <div>
                                            <PasswordInput
                                                label={'Current Password'}
                                                name={'old_password'}
                                            />
                                        </div>
                                        <div className='mt-5'>
                                            <PasswordInput
                                                label={'New Password'}
                                                name={'new_password'}
                                            />
                                        </div>
                                        <div className='mt-5'>
                                            <PasswordInput
                                                label={'Confirm New Password'}
                                                name={'confirm_passwrod'}
                                            />
                                        </div>
                                        <div className='text-center'>
                                            <Button
                                                onClick={handleSubmit(onSubmit)}
                                                className='btn mt-5 change-pwd-btn text-center'
                                            >
                                                Change Password
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </FormProvider>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default ChangePassword

const PasswordInput = ({ label, name }) => {
    const [showPassword, setShowPassword] = useState(false)
    const {
        register,
        formState: { errors },
    } = useFormContext()

    return (
        <div className='change-pwd-input-wrap'>
            <label className='input_label'>{label}</label>
            <div className='input-with-icon-wrap'>
                <PasswordIcon className='icon change_pwd_icon' />
                <input
                    placeholder='**********'
                    type={!showPassword ? 'password' : 'text'}
                    className={`change_pwd_input form-control ${errors?.[name] ? 'error' : ''
                        }`}
                    {...register(name, {
                        required: true,
                        minLength: 8,
                        validate: (value) => {
                            return !!value.trim()
                        },
                    })}
                />
                {!showPassword ? (
                    <PassworHidedIcon
                        className='icon-rigth change_pwd_icon-rigth'
                        onClick={() => setShowPassword(true)}
                    />
                ) : (
                    <PasswordShowIcon
                        className='icon-rigth change_pwd_icon-rigth'
                        onClick={() => setShowPassword(false)}
                    />
                )}
            </div>
            <span className='text-danger mt-2 '>
                <ErrorMessage
                    errors={errors}
                    name={name}
                    message={passwordErrorMessage}
                />
            </span>
        </div>
    )
}
