import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './auth.css'

function CancelPayment() {
    const navigate = useNavigate()

    useEffect(() => {
        let a = setTimeout(() => {
            navigate('/protrade')
        }, 2000)
        return () => {
            clearTimeout(a)
        }
    }, [])

    
    return (
        <>
            <div
                style={{
                    textAlign: 'center',
                    padding: '40px 0',
                }}
            >
                <div className='cancel success card'>
                    <div
                        style={{
                            borderRadius: '200px',
                            height: '200px',
                            width: '200px',
                            background: '#F8FAF5',
                            margin: '0 auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <i className='checkmark'>X</i>
                    </div>
                    <h1>Payment is Cancel!</h1>
                    <p> Redirecting...</p>
                </div>
            </div>
        </>
    )
}

export default CancelPayment
