import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from './action/AuthRedux'
import ThemeSlice from './action/ThemeRedux'
import ProfileSlice from './action/ProfileRedux'
import ChangePasswordSlice from './action/ChangePasswordRedux'
import DashboardSlice from './action/DashboardRedux'

const Store = configureStore({
    reducer: {
        auth: AuthSlice,
        theme: ThemeSlice,
        profile: ProfileSlice,
        changePassword: ChangePasswordSlice,
        dashboard: DashboardSlice,
    },
})

export default Store
