import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { errorMessage } from 'constant/const'
import axiosInstance from 'utils/axiosConfig'
import { toastError, toastSuccess } from 'utils/toaster'

const initialState = {
    profileData: null,
    loading: false,
    subscriptionInfo: null,
    updateSubscription: false,
    updatePlanInfo: null,
    mobileCarrier: [],
}

export const getProfileAction = createAsyncThunk(
    'profile/getProfileAction',
    async () => {
        try {
            const response = await axiosInstance.get(`/user/account/info/`)
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const updateUserAction = createAsyncThunk(
    'profile/updateUserAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/user/account/update/`,
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data;' },
                }
            )
            toastSuccess(response?.data?.message)
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const cancelSubscriptionAction = createAsyncThunk(
    'profile/cancelSubscriptionAction',
    async () => {
        try {
            const response = await axiosInstance.get(`/subscription/cancel/`)
            toastSuccess(response?.data?.message)
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const updateSubscriptionPlanAction = createAsyncThunk(
    'profile/updateSubscriptionPlanAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(`/subscription/change/`, {
                data: data,
            })
            toastSuccess(response?.data?.message)
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)
export const getMobileCarrierAction = createAsyncThunk(
    'profile/getMobileCarrierAction',
    async () => {
        try {
            const response = await axiosInstance.get(
                `/user/mobile-carrier/get/`
            )
            // toastSuccess(response?.data?.message)
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const successfullyUpdateSubscriptionPlanAction = createAsyncThunk(
    'profile/successfullyUpdateSubscriptionPlanAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/subscription/verify_payment/`,
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data;' },
                }
            )
            toastSuccess(response?.data?.message)
            return response?.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getProfileAction.pending, (state) => {
                state.loading = true
                state.profileData = null
                state.subscriptionInfo = null
            })
            .addCase(getProfileAction.fulfilled, (state, acton) => {
                state.loading = false
                state.profileData = acton.payload
                state.subscriptionInfo = acton.payload.payment
            })
            .addCase(getProfileAction.rejected, (state) => {
                state.loading = false
                state.profileData = null
                state.subscriptionInfo = null
            })
            .addCase(updateUserAction.pending, (state) => {
                state.loading = true
                state.profileData = null
            })
            .addCase(updateUserAction.fulfilled, (state, acton) => {
                state.loading = false
                state.profileData = acton.payload
            })
            .addCase(updateUserAction.rejected, (state) => {
                state.loading = false
                state.profileData = null
            })
            .addCase(cancelSubscriptionAction.pending, (state) => {
                state.loading = true
                state.updateSubscription = false
            })
            .addCase(cancelSubscriptionAction.fulfilled, (state) => {
                state.loading = false
                state.updateSubscription = true
            })
            .addCase(cancelSubscriptionAction.rejected, (state) => {
                state.loading = false
                state.updateSubscription = false
            })
            .addCase(updateSubscriptionPlanAction.pending, (state) => {
                state.loading = true
                state.updateSubscription = false
                state.updatePlanInfo = null
            })
            .addCase(
                updateSubscriptionPlanAction.fulfilled,
                (state, action) => {
                    state.loading = false
                    state.updateSubscription = true
                    state.updatePlanInfo = action.payload
                }
            )
            .addCase(updateSubscriptionPlanAction.rejected, (state) => {
                state.loading = false
                state.updateSubscription = false
                state.updatePlanInfo = null
            })
            .addCase(getMobileCarrierAction.pending, (state) => {
                state.loading = true
                state.mobileCarrier = []
            })
            .addCase(getMobileCarrierAction.fulfilled, (state, action) => {
                state.loading = false
                state.mobileCarrier = action.payload
            })
            .addCase(getMobileCarrierAction.rejected, (state) => {
                state.loading = false
                state.mobileCarrier = []
            })
            .addCase(
                successfullyUpdateSubscriptionPlanAction.pending,
                (state) => {
                    state.loading = true
                    state.updateSubscription = false
                    state.updatePlanInfo = null
                }
            )
            .addCase(
                successfullyUpdateSubscriptionPlanAction.fulfilled,
                (state, action) => {
                    state.loading = false
                    state.updateSubscription = true
                    state.updatePlanInfo = action.payload
                }
            )
            .addCase(
                successfullyUpdateSubscriptionPlanAction.rejected,
                (state) => {
                    state.loading = false
                    state.updateSubscription = false
                    state.updatePlanInfo = null
                }
            )
    },
})

export default ProfileSlice.reducer
