import { ErrorMessage } from '@hookform/error-message'
import { CustomInputBox, CustomModal, CustomSelect } from 'components'
import { EmailRegx, errorMessage } from 'constant/const'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import axiosInstance from 'utils/axiosConfig'
import { convertObjectToFormData } from 'utils/convertFormData'
import { toastError, toastSuccess } from 'utils/toaster'

function ContactUsModal({ isOpen, onClosModal }) {
    const [selectedValue, setSelectedValue] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const methods = useForm()
    const {
        handleSubmit, register,
        formState: { errors }, setValue, reset
    } = methods

    useEffect(() => {
        register('category', { value: "" })
        return () => { }
    }, [])

    const onCloseContactUsModal = () => {
        reset()
        onClosModal()
    }

    const onSubmit = async (data) => {
        setIsLoading(true)
        try {
            data.category = data.category || ''
            data.name = data.name.trim();

            let formData = convertObjectToFormData(data)
            let res = await axiosInstance.post("/user/account/contact-us/", formData, {
                headers: { 'Content-Type': 'multipart/form-data;' },
            })
            toastSuccess(res?.data?.message)
            setIsLoading(false)
            onCloseContactUsModal()

        } catch (err) {
            console.log("err", err)
            toastError(err?.response?.data?.message || err.message || errorMessage)
            setIsLoading(false)
            // throw err.response ? err.response.data : err.message
        }
    }


    return (
        <div>
            <form>
                <FormProvider {...methods}>
                    <CustomModal
                        show={isOpen}
                        closeModal={onCloseContactUsModal}
                        isCloseIcone={true}
                        footerComponent={
                            <>
                                <Button
                                    className='btn btn-protrade w-25'
                                    onClick={onCloseContactUsModal}
                                >
                                    Close
                                </Button>
                                <Button
                                    className='btn btn-protrade w-25'
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Loading...' : 'Submit'}
                                </Button>
                            </>
                        }
                        title={`Contact US `}
                        centered
                    >
                        <div className=''>
                            <CustomSelect
                                value={selectedValue}
                                options={[
                                    {
                                        label: `i'm interested in manual trading`,
                                        value: `i'm interested in manual trading`,
                                    },
                                    {
                                        label: `i'm interested in autonomous trading`,
                                        value: `i'm interested in autonomous trading`,
                                    },
                                ]}
                                // label={'Order BuyMarginSource'}
                                placeholder={'Please select categories'}
                                onChange={(e) => {
                                    setValue("category", e.value)
                                    setSelectedValue(e)
                                }}
                                className={`${errors?.category ? 'error-select-box' : ''
                                    }`}
                            />
                        </div>
                        <div className='mt-3'>
                            <CustomInputBox
                                label={'Name'}
                                name={'name'}
                                placeholder={'Please enter name'}
                                rules={{
                                    required: true,
                                    validate: (value) => {
                                        return !!value.trim()
                                    },
                                }}
                                className={`${errors?.name ? 'error' : ''
                                    }`}
                            />
                            <span className='text-danger mt-1 error-message'>
                                <ErrorMessage
                                    errors={errors}
                                    name='name'
                                    message={"Please Enter the name"}
                                />
                            </span>
                        </div>
                        <div className='mt-3'>
                            <CustomInputBox
                                label={'Email'}
                                name={'email'}
                                placeholder={'Please enter email'}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: EmailRegx,
                                        message: '',
                                    },
                                }}
                                className={`${errors?.email ? 'error' : ''
                                    }`}
                            />
                            <span className='text-danger mt-1 error-message'>
                                <ErrorMessage
                                    errors={errors}
                                    name='email'
                                    message={"Please Enter the Email address"}
                                />
                            </span>
                        </div>

                        <div className='form-group mt-3'>
                            <label className='text-capitalize lable-wrap'>
                                Message
                                <span className='text-danger'>*</span>
                            </label>
                            <textarea
                                className={`form-control ${errors?.message ? 'error' : ''
                                    }`}
                                placeholder={"Please enter the message"}
                                rows={5}
                                {...register('message', {
                                    required: true, validate: (value) => {
                                        return !!value.trim()
                                    },
                                })}
                            ></textarea>
                            <span className='text-danger mt-1 error-message'>
                                <ErrorMessage
                                    errors={errors}
                                    name='message'
                                    message={"Please Enter the message"}
                                />
                            </span>
                        </div>
                    </CustomModal>
                </FormProvider>
            </form>
        </div>
    )
}

export default ContactUsModal