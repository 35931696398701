import { lazy } from 'react'
import LogIn from './Auth/LogIn'
import SignUp from './Auth/SignUp'
import ResetPassword from './Auth/ResetPassword'
import SubscriptionPlan from './Auth/SubscriptionPlan'
import PageNotFound from './PageNotFound'
import ForgotPassword from './Auth/ResetPassword/ForgotPassword'
import ChangePassword from './protrade/ChangePassword'
const MyProfile = lazy(() => import('./protrade/MyProfile'))
const Dashboard = lazy(() => import('./protrade/Dashboard'))
const StockDetailsAddEdit = lazy(() =>
    import('./protrade/Dashboard/StockDetails/StockDetailsAddEdit')
)
import Success from './Auth/Success'
import CancelPayment from './Auth/CancelPayment'

// landing pages
import Home from './Home'

export {
    LogIn,
    SignUp,
    ResetPassword,
    SubscriptionPlan,
    Dashboard,
    MyProfile,
    ChangePassword,
    PageNotFound,
    StockDetailsAddEdit,
    ForgotPassword,
    Success,
    CancelPayment,
    Home
}
