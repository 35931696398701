import { BackMobileIcon, LogoutIcon, MainLogoImage } from 'assets'
import React, { Fragment, useEffect, useRef } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { toggleSlider } from 'store/action/ThemeRedux'
import { menuList } from './menuList'
import { logoutUserAction } from 'store/action/AuthRedux'
import { useOutsideClick } from 'Hooks'
import { customSweetalert } from 'utils/customSweetalert'
import { convertObjectToFormData } from 'utils/convertFormData'

function Sidebar() {
    const themeRedux = useSelector((state) => state.theme)
    const { mobileSilder } = themeRedux
    const authData = useSelector((state) => state.auth)
    const { user } = authData
    const dispatch = useDispatch()
    const sliderRef = useRef(null)
    useOutsideClick(sliderRef, () => {
        dispatch(toggleSlider(false))
    })

    return (
        <div
            className={`main-sidebar mobile-sidebar ${
                mobileSilder ? 'show-mobile' : ''
            }`}
            ref={sliderRef}
        >
            <div>
                <div className='sidebar-brand'>
                    <Link to={'#'}>
                        <img
                            alt='logo'
                            src={MainLogoImage}
                            className='header-logo'
                        />
                    </Link>
                    <BackMobileIcon
                        className='back-mobile-icon'
                        onClick={() => {
                            dispatch(toggleSlider(false))
                        }}
                    />
                </div>
                <ul className='sidebar-menu'>
                    {menuList &&
                        menuList.length > 0 &&
                        menuList.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <MenuLists items={item} />
                                </Fragment>
                            )
                        })}
                    <li className='dropdown Logout'>
                        <span
                            className='nav-link'
                            onClick={async () => {
                                let alertRespones = await customSweetalert(
                                    'Are you sure you want logged out?',
                                    ['No', 'Yes']
                                )
                                if (alertRespones) {
                                    let formData = convertObjectToFormData({
                                        refresh: user?.refresh,
                                        access: user?.access,
                                    })
                                    dispatch(logoutUserAction(formData))
                                    dispatch(toggleSlider(false))
                                }
                            }}
                        >
                            <LogoutIcon />
                            <span>Logout</span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar

export const MenuLists = (props) => {
    const { items } = props
    const { name, icon, path, activeName, activeIcon } = items
    const location = useLocation()
    const { pathname } = location
    const splitLocation = pathname.split('/')
    const [activeTab, setActiveTab] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (
            splitLocation[1].toLowerCase() === activeName.toLowerCase() ||
            splitLocation[splitLocation.length - 1].toLowerCase() ===
                activeName.toLowerCase()
        ) {
            setActiveTab(true)
        } else {
            setActiveTab(false)
        }

        return () => {}
    }, [splitLocation])

    return (
        <li
            className={`dropdown ${activeTab ? 'active' : ''}`}
            onClick={() => {
                dispatch(toggleSlider(false))
            }}
        >
            <Link to={path} className='nav-link' title={name}>
                {activeTab ? activeIcon : icon}
                <span>{name}</span>
            </Link>
        </li>
    )
}
