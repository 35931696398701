import { CustomInputBox, CustomModal, Loader } from 'components'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    resendPasswordResetEmailAction,
    verifyUserAction,
} from 'store/action/AuthRedux'
import { convertObjectToFormData } from 'utils/convertFormData'

function EmailVerfiy({ navigateUrl }) {
    const [count, setCount] = useState(60)
    const methods = useForm()
    const authData = useSelector((state) => state.auth)
    const { user, loading } = authData
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const countdown = setInterval(() => {
            if (count === 0) {
                clearInterval(countdown)
            } else {
                setCount(count - 1)
            }
        }, 1000)

        return () => clearInterval(countdown)
    }, [count])

    useEffect(() => {
        if (user && user?.active_status && !user?.verifyLoginScreen) {
            navigate(navigateUrl)
        }
        return () => {}
    }, [user])

    const {
        handleSubmit,
        formState: { errors },
    } = methods

    const onSubmit = (data) => {
        let objData = {
            ...data,
            username: user.username,
        }
        let formData = convertObjectToFormData(objData)
        dispatch(verifyUserAction(formData))
    }
    return (
        <div>
            {loading && <Loader />}
            <FormProvider {...methods}>
                <CustomModal
                    show={true}
                    closeModal={() => {}}
                    footerComponent={
                        <>
                            <Button
                                className='btn btn-danger'
                                onClick={() => {
                                    let objData = {
                                        username: user.username,
                                    }
                                    let formData =
                                        convertObjectToFormData(objData)
                                    setCount(60)
                                    dispatch(
                                        resendPasswordResetEmailAction(formData)
                                    )
                                }}
                                disabled={count}
                            >
                                {`Resend OTP ${count ? count + 's' : ''}`}
                            </Button>
                            <Button
                                className='btn btn-protrade w-25'
                                onClick={handleSubmit(onSubmit)}
                            >
                                Verify
                            </Button>
                        </>
                    }
                    title={`Email verify ${user?.email ? user?.email : ''} `}
                    centered
                >
                    <CustomInputBox
                        label={'Verification code'}
                        name={'verification_code'}
                        placeholder={'Please enter verification code'}
                        rules={{
                            required: true,
                            pattern: {
                                value: /^\d{6}$/,
                                message: '',
                            },
                        }}
                        className={`${
                            errors?.verification_code ? 'error' : ''
                        }`}
                    />
                </CustomModal>
            </FormProvider>
        </div>
    )
}

export default EmailVerfiy
