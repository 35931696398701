import { AutonomousTradingImage, SelfDirectedImage } from 'assets'
import React from 'react'
import { Link } from 'react-router-dom'

function EasySignUpProcess() {
    return (
        <div className='section-wrap easy-sing-process-section'>
            <h1 className='heading-title'>Easy Sign-Up Process</h1>
            <p className='sub-title'>Getting Started with Protrade+</p>
            <p className='sub-title'>
                Your journey to smarter trading is just a few clicks away.
            </p>

            <div className='row mt-5 esay-process-Investing-section'>
                <div className='col-12 col-lg-6'>
                    <div className='esay-process-wrap'>
                        <img src={SelfDirectedImage} />
                        <h3>Manual Trading</h3>
                        <p className='sub-title'>
                            Protrade+ provides real-time market data, historical
                            trends, and comprehensive stock information to help
                            you make informed decisions.
                        </p>
                    </div>
                    
                </div>
                <div className='col-12 col-lg-6'>
                    <div className='esay-process-wrap'>
                        <img src={AutonomousTradingImage} />
                        <h3>Autonomous Trading</h3>
                        <p className='sub-title'>
                            Every trader is unique. We understand that and offer
                            personalized trading strategies tailored to your
                            risk tolerance, investment goals, and trading style.
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <div className='timeline'>
                    <ul>
                        <li>
                            <div className='content'>
                                <h3>Join Protrade+</h3>
                                <p>
                                    Visit Protrade+{' '}
                                    <Link
                                        to={
                                            '/auth/signup'
                                        }
                                        target='_blank'
                                    >
                                        Sign Up
                                    </Link>{' '}
                                    and create your account.{' '}
                                </p>
                            </div>
                        </li>

                        <li>
                            <div className='content'>
                                <h3>Select Your Plan</h3>
                                <p>
                                    Choose the subscription that aligns with
                                    your trading goals.
                                </p>
                            </div>
                        </li>

                        <li>
                            <div className='content'>
                                <h3>Personalize Your Experience</h3>
                                <p>
                                    Enter your details, set your preferences,
                                    and add your preferred stocks for trading.{' '}
                                </p>
                            </div>
                        </li>

                        <li>
                            <div className='content'>
                                <h3>Integrate your IBKR Account</h3>
                                <p>
                                    Hands-free. autonomous trading for passive
                                    income
                                </p>
                            </div>
                        </li>

                        <div style={{ clear: 'both' }}></div>
                    </ul>
                </div>
                <p style={{ margin: 0, textAlign: 'center' }}>
                    We handle the complexities you can focus on your trading.
                </p>
            </div>
        </div>
    )
}

export default EasySignUpProcess
