import { HamburgeIcon, LogoutImage, MainLogoImage } from 'assets'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logoutUserAction } from 'store/action/AuthRedux'
import { toggleSlider } from 'store/action/ThemeRedux'
import { convertObjectToFormData } from 'utils/convertFormData'
import { customSweetalert } from 'utils/customSweetalert'
import { menuList } from './menuList'
import { MenuLists } from './Sidebar'

function Header() {
    const dispatch = useDispatch()
    const themeRedux = useSelector((state) => state.theme)
    const { mobileTitle } = themeRedux
    const authData = useSelector((state) => state.auth)
    const { user } = authData

    const fullName = () => {
        return `${user?.first_name || ''} ${user?.last_name || ''} `
    }
    return (
        <div className='header-section'>
            <nav className='navbar navbar-expand-lg  main-navbar fixed-top'>
                <Link to={'/protrade/dashboard'} className='logo-section'>
                    <img
                        alt='logo'
                        src={MainLogoImage}
                        className='header-logo'
                    />
                </Link>
                <HamburgeIcon
                    className='hamburgeIcon'
                    onClick={() => {
                        dispatch(toggleSlider(true))
                    }}
                />
                {mobileTitle && (
                    <span className='mobile-title'> {mobileTitle}</span>
                )}
                <div className='navbar-nav navbar-right align-items-center gap-3'>
                    <ul className='navbar-nav ms-auto mb-2 mb-lg-0 mobile-hidden-menu'>
                        {menuList &&
                            menuList.length > 0 &&
                            menuList.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <MenuLists items={item} />
                                    </Fragment>
                                )
                            })}
                    </ul>
                    <ul className='navbar-nav navbar-right align-items-center gap-3 ml-4'>
                        <li>
                            <div className='user-name text-capitalize'>
                                {user?.first_name ? fullName() : user?.username}
                            </div>
                            <span className='other-user-name'>
                                ({user?.username || ''})
                            </span>
                        </li>
                        <li
                            onClick={async () => {
                                let alertRespones = await customSweetalert(
                                    'Are you sure you want logged out?',
                                    ['No', 'Yes']
                                )
                                if (alertRespones) {
                                    let formData = convertObjectToFormData({
                                        refresh: user?.refresh,
                                        access: user?.access,
                                    })
                                    dispatch(logoutUserAction(formData))
                                }
                            }}
                        >
                            <div className='logout-button'>
                                <img src={LogoutImage} alt='LogoutImage' />
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Header
