import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import './inputBox.css'

function CustomInputBox(props) {
    const { label, type, name, placeholder, rules, className, ...other } = props

    const { control } = useFormContext()

    return (
        <div className='form-group'>
            <label className='text-capitalize lable-wrap'>
                {label}
                {rules?.required && <span className='text-danger'>*</span>}
            </label>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <input
                        type={type}
                        {...field}
                        className={`form-control input-box ${
                            className ? className : ''
                        }`}
                        placeholder={placeholder}
                        {...other}
                    />
                )}
            />
        </div>
    )
}

export default CustomInputBox
