import React from 'react'
import './loader.css'
function Loader() {
    return (
        <div className='loader-container'>
            <div className='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className='overlay'></div>
            <div className='loader'></div>
        </div>
    )
}

export default Loader
