import React, { useEffect, useState } from 'react'
import '../auth.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import {
    BackArrowIcon,
    PassworHidedIcon,
    PasswordIcon,
    PasswordShowIcon,
} from 'assets'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { passwordErrorMessage } from 'constant/const'
import { toastError } from 'utils/toaster'
import { convertObjectToFormData } from 'utils/convertFormData'
import { useDispatch, useSelector } from 'react-redux'
import { forgotpasswordAction } from 'store/action/AuthRedux'
import { Loader } from 'components'
import { ErrorMessage } from '@hookform/error-message'

function ForgotPassword() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const methods = useForm()
    const dispatch = useDispatch()
    const authData = useSelector((state) => state.auth)
    const { passwordReset, loading } = authData
    const { handleSubmit } = methods

    useEffect(() => {
        if (!searchParams.get('reset-token') && !searchParams.get('uidb64')) {
            navigate('/auth')
        }
        return () => { }
    }, [searchParams])

    useEffect(() => {
        if (passwordReset) {
            navigate('/auth')
        }
        return () => { }
    }, [passwordReset])

    const onSubmit = (data) => {
        if (data.new_passwrod === data.confirm_passwrod) {
            let objData = convertObjectToFormData({
                new_password: data.new_passwrod,
                token: searchParams.get('reset-token'),
                uidb64: searchParams.get('uidb64'),
            })
            dispatch(forgotpasswordAction(objData))
        } else {
            toastError(
                'Password not match, please check your confirem password'
            )
        }
    }

    return (
        <div className='auth-section'>
            {loading && <Loader />}
            <div className='navigate-auth-link'>
                <p>
                    <b onClick={() => navigate('/auth/signin')}>
                        BACK TO SIGN IN
                    </b>
                </p>
            </div>
            <div className='auth-form-section'>
                {/* <ResetPasswordIcon /> */}
                <h1 className='auth-title mt-4'>
                    Reset password{' '}
                    <span className='text-green'>
                        PROTRADE<sup>+</sup>
                    </span>
                </h1>
                <p className='auth-sub-title reset-info'>
                    Forgot your password
                </p>
                <FormProvider {...methods}>
                    <form>
                        <PasswordInput
                            label={'New Password'}
                            name={'new_passwrod'}
                            placeholder={"enter the new password"}
                        />
                        <PasswordInput
                            label={'Confirm New Password'}
                            name={'confirm_passwrod'}
                            placeholder={"enter the confirm password"}
                        />

                        <Button
                            type='submit'
                            className='btn-protrade btn-label-icon mt-3 reset-btn'
                            onClick={handleSubmit(onSubmit)}
                        >
                            <span>Reset Password</span>
                            <BackArrowIcon />
                        </Button>
                    </form>
                </FormProvider>
                <p className='other-info-reset-password mt-4'>
                    Please contact us if you have any trouble resetting your
                    password.
                </p>
            </div>
        </div>
    )
}

export default ForgotPassword

const PasswordInput = ({ label, name, placeholder }) => {
    const [showPassword, setShowPassword] = useState(false)

    const {
        register,
        formState: { errors },
    } = useFormContext()

    return (
        <>
            <div className='input_container mt-3'>
                <PasswordIcon className='icon' />
                <input
                    placeholder={placeholder}
                    title='Inpit title'
                    type={!showPassword ? 'password' : 'text'}
                    className={`input_field form-control ${errors?.[name] ? 'error' : ''
                        }`}
                    {...register(name, {
                        required: true,
                        minLength: 8,
                        validate: (value) => {
                            return !!value.trim()
                        },
                    })}
                />
                {!showPassword ? (
                    <PassworHidedIcon
                        className='icon-rigth'
                        onClick={() => setShowPassword(true)}
                    />
                ) : (
                    <PasswordShowIcon
                        className='icon-rigth'
                        onClick={() => setShowPassword(false)}
                    />
                )}
            </div>
            <span className='text-danger mt-2 error-message'>
                <ErrorMessage
                    errors={errors}
                    name={name}
                    message={passwordErrorMessage}
                />
            </span>
        </>
    )
}
