import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import './inputBox.css'
import CurrencyInput from 'react-currency-input-field'

function CustomCurrencyInput(props) {
    const {
        label,
        type,
        name,
        placeholder,
        rules,
        className,
        prefix,
        onValueChange,
        suffix,
        ...other
    } = props

    const { control } = useFormContext()

    return (
        <div className='form-group'>
            <label className='text-capitalize lable-wrap'>
                {label}
                {rules?.required && <span className='text-danger'>*</span>}
            </label>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <CurrencyInput
                        placeholder={placeholder}
                        className={`form-control input-box ${
                            className ? className : ''
                        }`}
                        // onChange={field.onChange}
                        // defaultValue={field.value}
                        decimalsLimit={2}
                        decimalScale={2}
                        onValueChange={onValueChange}
                        prefix={prefix ? prefix : null}
                        suffix={suffix ? '%' : null}
                        value={field?.value ? field?.value : field?.value}
                        {...other}
                    />
                )}
            />
        </div>
    )
}

export default CustomCurrencyInput
