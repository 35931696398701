import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import './customTable.css'
import { AscSortIcon, DescSortIcon, SortIcon } from 'assets'

function CustomTable({
    data,
    columns,
    globalFilter,
    notFoundComponents,
    notFoundFlagComponents,
}) {
    const [sorting, setSorting] = useState([])

    const table = useReactTable({
        data: data,
        columns,
        state: {
            sorting,
            globalFilter,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    })

    return (
        <div>
            <Table responsive hover >
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th key={header.id}>
                                    {header.isPlaceholder ? null : (
                                        <div
                                            {...{
                                                className:
                                                    header.column.getCanSort()
                                                        ? 'cursor-pointer select-none full-width'
                                                        : 'full-width',
                                                onClick:
                                                    header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {header.column.getIsSorted() ? (
                                                <span>
                                                    {header.column.getIsSorted() ===
                                                    'desc' ? (
                                                        <DescSortIcon className='sort-icon' />
                                                    ) : (
                                                        <AscSortIcon className='sort-icon' />
                                                    )}
                                                </span>
                                            ) : header.column.columnDef
                                                  .enableSorting ? (
                                                <SortIcon className='sort-icon' />
                                            ) : null}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.length === 0 ? (
                        <tr>
                            <td
                                style={{
                                    textAlign: 'center',
                                    borderBottom: 'none',
                                }}
                                colSpan={columns.length}
                            >
                                {notFoundFlagComponents
                                    ? notFoundComponents
                                    : 'No records found.'}
                            </td>
                        </tr>
                    ) : (
                        table.getRowModel().rows.map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                    )}
                </tbody>
            </Table>
        </div>
    )
}

export default CustomTable
