import React from 'react'
import { DebounceInput } from 'react-debounce-input'
import './debounceInput.css'

function DebounceInputBox({
    placeholder,
    onSearch,
    showIcon,
    icon,
    className,
    value,
    minLength,
    ...other
}) {
    return (
        <div className='form-group'>
            <div className='search-wrap'>
                {showIcon && <div className='input-group-prepend'>{icon}</div>}
                <DebounceInput
                    value={value}
                    type='text'
                    className={`form-control search-input currency ${className}`}
                    placeholder={placeholder}
                    debounceTimeout={900}
                    onChange={(e) => onSearch(e.target.value)}
                    minLength={minLength || 2}
                    {...other}
                />
            </div>
        </div>
    )
}

export default DebounceInputBox
