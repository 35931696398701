import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import ContactUsModal from './ContactUsModal'

function FinancialFuture() {
    const [isOpen, setIsOpen] = useState(false)
    const onClosModal = () => {
        setIsOpen(false)
    }
    return (
        <div className='section-wrap financial-section'>
            <Container>
                <div className='text-center'>
                    <h3 className="heading-title">Your financial future begins here.</h3>
                    <p className="sub-title">Start with Protrade+&nbsp;Today</p>
                    <div className='section-btn-group'>
                        <Button
                            className="btn btn-get-started-black"
                            onClick={() => window.open("/auth/signup", '_blank')}
                        >
                            Sign Up Now
                        </Button>
                        <Button
                            className="btn btn-get-started-black  "
                            onClick={() => setIsOpen(true)}
                        >
                            Contact Us
                        </Button>
                    </div>
                    <ContactUsModal isOpen={isOpen} onClosModal={onClosModal} />
                </div>
            </Container>
        </div>
    )
}

export default FinancialFuture