import { PageNotFoundIcon } from 'assets'
import React from 'react'
import { Button } from 'react-bootstrap'
import './pageNotFound.css'
import { useNavigate } from 'react-router-dom'

function PageNotFound() {
    const navigate = useNavigate()
    return (
        <div className='page-not-found-wrap'>
            <div className='not-found-icon'>
                <PageNotFoundIcon />
            </div>
            <div className='page-not-found-content'>
                <h2>PAGE NOT FOUND</h2>
                <p>we couldn't find the page </p> <p> you were looking for</p>
                <Button onClick={() => navigate('/')}>Back to home</Button>
            </div>
        </div>
    )
}

export default PageNotFound
