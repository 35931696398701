export const EmailRegx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const PasswordRegx =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&^#+-\/])[A-Za-z\d@$!%*?&^#+-\/]{8,}$/

export const UserNameRegx = /^[A-Za-z][A-Za-z0-9_]{3,29}$/

export const proTradePlus = 'proTradePlus'
export const errorMessage = 'Something is wrong!!'

export const subscriptionPlanConst = {
    FREE: 'FREE',
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY',
}

export const strategyConst = {
    SHORT: 'SHORT',
    MEDIUM: 'MEDIUM',
    LONG: 'LONG',
}

export const passwordErrorMessage = "Password must be at least 8 characters"
// export const userNameErrroMessage = "A username can only include letters, underscores, and a minimum length 4"
export const userNameErrroMessage = "Username must be at least 3 characters"