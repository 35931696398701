import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Footor from './Footor'
import './mainLayout.css'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { proTradePlus } from 'constant/const'
import { jwtTokenVerfiy } from 'utils/jwtTokenVerfiy'
import { logoutAction } from 'store/action/AuthRedux'
import { toastError } from 'utils/toaster'

function MainLayout() {
    const themeRedux = useSelector((state) => state.theme)
    const { mobileSilder } = themeRedux
    const authData = useSelector((state) => state.auth)
    const { isLogin, user: authUser } = authData
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation()

    useEffect(() => {
        let user = localStorage.getItem(proTradePlus)
        if (user) {
            let isLogins = JSON.parse(user)
            if (!isLogins.isLogin) {
                navigate('/auth')
            }
        } else {
            navigate('/auth')
        }

        return () => {}
    }, [isLogin])

    useEffect(() => {
        if (authUser && authUser?.access) {
            let logOutUser = jwtTokenVerfiy(authUser)
            if (logOutUser) {
                toastError('Session timeout, please login again.')
                setTimeout(() => {
                    dispatch(logoutAction())
                }, 1000)
            }
        }
        return () => {}
    }, [navigate])

    // scroll to Top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <div>
            <div
                className={`main-layout-wrap ${
                    mobileSilder ? 'show-backdrop' : ''
                }`}
            >
                <Header />
                {/* only mobile for sidebar */}
                <Sidebar />
                <div className='main-content'>
                    <Outlet />
                </div>
                <Footor />
            </div>
        </div>
    )
}

export default MainLayout
