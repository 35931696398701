import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getSubscriptionPalAction } from 'store/action/AuthRedux'

function TransparentPricing() {
    const authData = useSelector((state) => state.auth)
    const { planArr } = authData
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSubscriptionPalAction())
        return () => {}
    }, [])
    return (
        <div className='section-wrap pt-0'>
            <div className='title-section-wrap'>
                <div className='title-section transparent-title-section '>
                    <h3 className='heading-title'>
                        Transparent Pricing for Every Trader
                    </h3>
                    <h3 className='sub-title'>
                        Choose the plan that's right for you, with no surprises.
                    </h3>
                </div>
            </div>
            <div>
                <div className='subscription-wrap mt-5'>
                    {planArr &&
                        planArr?.length > 0 &&
                        planArr?.map((item, i) => {
                            return (
                                <LandingSubscriptionPlanCard
                                    subscriptionPlanInfo={item}
                                    key={i}
                                />
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export default TransparentPricing

const LandingSubscriptionPlanCard = ({ subscriptionPlanInfo }) => {
    const { currency, description, plan, price } = subscriptionPlanInfo

    return (
        <div className='subscription-section landing-subscription'>
            <div className='price-section'>
                <h3 className='price-lable'>{plan.toLowerCase()}</h3>
                <div className='d-flex align-items-baseline  amount-wrap'>
                    <h2>${price}</h2>
                    <sub>/{currency}</sub>
                </div>
            </div>
            <hr />
            <div
                className='description-info'
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            />
            <Button
                className='btn-protrade btn-subscription btn-get-started p-3 mt-3 text-cen'
                onClick={() =>
                    window.open(
                        '/auth/signup',
                        '_blank'
                    )
                }
            >
                <span>Sign Up Now</span>
            </Button>
        </div>
    )
}
