import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { successPaymentAction } from 'store/action/AuthRedux'
import { convertObjectToFormData } from 'utils/convertFormData'
import './auth.css'
import { successfullyUpdateSubscriptionPlanAction } from 'store/action/ProfileRedux'

function Success() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const authData = useSelector((state) => state.auth)
    const { isLogin, loading } = authData

    const subscriptionData = useSelector((state) => state.profile)
    const { updateSubscription, loading: suLoading } = subscriptionData
    const [queryObj, setQueryObj] = useState(null)

    useEffect(() => {
        let query = new URLSearchParams(location?.search)
        const params = {}
        query.forEach((value, key) => {
            params[key] = value
        })
        setQueryObj(params)

        if (params) {
            if (params?.type === 'new') {
                let fromData = convertObjectToFormData(params)
                dispatch(successPaymentAction(fromData))
            } else if (params?.type === 'update') {
                let fromData = convertObjectToFormData(params)
                dispatch(successfullyUpdateSubscriptionPlanAction(fromData))
            } else {
                navigate('/auth/signin')
            }
        } else {
            navigate('/auth/signin')
        }
        return () => {}
    }, [location])

    useEffect(() => {
        if (!loading && !suLoading) {
            if (updateSubscription && queryObj?.type === 'update') {
                navigate('/protrade/profile')
            }
            if (isLogin && queryObj?.type === 'new') {
                navigate('/protrade')
            }
        }

        return () => {}
    }, [queryObj, updateSubscription, isLogin])

    return (
        <>
            <div
                style={{
                    textAlign: 'center',
                    padding: '40px 0',
                }}
            >
                <div className='success card'>
                    <div
                        style={{
                            borderRadius: '200px',
                            height: '200px',
                            width: '200px',
                            background: '#F8FAF5',
                            margin: '0 auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <i className='checkmark'>✓</i>
                    </div>
                    <h1>Payment is Successfully Done!!</h1>
                    <p>Please wait...</p>
                </div>
            </div>
        </>
    )
}

export default Success
