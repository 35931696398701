import React, { useEffect, useState } from 'react'
import '../auth.css'
import {
    BackArrowIcon,
    GoogleIcon,
    PassworHidedIcon,
    PasswordIcon,
    PasswordShowIcon,
    ProfileIcon,
} from 'assets'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { useForm } from 'react-hook-form'
import { UserNameRegx, passwordErrorMessage, proTradePlus, userNameErrroMessage } from 'constant/const'
import { useDispatch, useSelector } from 'react-redux'
import {
    loginUserAction,
    resendPasswordResetEmailAction,
    resetUserInfo,
} from 'store/action/AuthRedux'
import { convertObjectToFormData } from 'utils/convertFormData'
import EmailVerfiy from '../components/EmailVerfiy'
import { Loader } from 'components'
import { useSocialLoginHook } from 'Hooks'
import { ErrorMessage } from '@hookform/error-message'

function LogIn() {
    const [showPassword, setShowPassword] = useState(false)
    const [isOpenVerifyModal, setIsOpenVerifyModal] = useState(false)
    const { googleLogin } = useSocialLoginHook()
    const navigate = useNavigate()
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm()

    const dispatch = useDispatch()

    const authData = useSelector((state) => state.auth)
    const { isLogin, user, loading } = authData

    useEffect(() => {
        if (isLogin) {
            navigate('/protrade')
        } else {
            let users = localStorage.getItem(proTradePlus)
            let userData = JSON.parse(users)
            if (userData && !userData?.isLogin) {
                dispatch(resetUserInfo())
                localStorage.removeItem(proTradePlus)
            }
        }
        return () => { }
    }, [isLogin])

    useEffect(() => {
        if (user && user?.active_status) {
            setIsOpenVerifyModal(false)
        }
        if (user && !user?.active_status && user?.verifyLoginScreen) {
            setIsOpenVerifyModal(true)
            let formData = convertObjectToFormData({ username: user.username })
            dispatch(resendPasswordResetEmailAction(formData))
            reset()
        } else if (
            user &&
            (user?.subscription_plan === null || !user?.payment_status)
        ) {
            navigate('/auth/subscription-plan')
        }
        return () => { }
    }, [user])

    const onSubmit = (data) => {
        let formData = convertObjectToFormData(data)
        dispatch(loginUserAction(formData))
    }

    return (
        <div className='auth-section'>
            {loading && <Loader />}
            <div className='navigate-auth-link'>
                <p>
                    Not a member yet?
                    <b onClick={() => navigate('/auth/signup')}> JOIN NOW</b>
                </p>
            </div>
            <div className='auth-form-section'>
                <h1 className='auth-title'>
                    WELCOME BACK{' '}
                    <span className='text-green'>
                        PROTRADE<sup>+</sup>
                    </span>
                </h1>
                <p className='auth-sub-title'>SIGN IN TO CONTINUE</p>
                <form>
                    <div className='input_container'>
                        <ProfileIcon className='icon' />
                        <input
                            placeholder='Please enter username'
                            type='text'
                            className={`input_field form-control ${errors?.username ? 'error' : ''
                                }`}
                            {...register('username', {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim()
                                },
                                minLength: 3
                                // pattern: {
                                //     value: UserNameRegx,
                                //     message: '',
                                // },
                            })}
                        />
                    </div>
                    <span className='text-danger mt-2 error-message'>
                        <ErrorMessage
                            errors={errors}
                            name='username'
                            message={userNameErrroMessage}
                        />
                    </span>
                    <div className='input_container mt-3'>
                        <PasswordIcon className='icon' />
                        <input
                            placeholder='Please enter the password'
                            title='Inpit title'
                            type={!showPassword ? 'password' : 'text'}
                            className={`input_field form-control ${errors?.password ? 'error' : ''
                                }`}
                            {...register('password', {
                                required: true,
                                minLength: 8,
                                validate: (value) => {
                                    return !!value.trim()
                                },
                            })}
                        />
                        {!showPassword ? (
                            <PassworHidedIcon
                                className='icon-rigth'
                                onClick={() => setShowPassword(true)}
                            />
                        ) : (
                            <PasswordShowIcon
                                className='icon-rigth'
                                onClick={() => setShowPassword(false)}
                            />
                        )}
                    </div>
                    <span className='text-danger mt-2 error-message'>
                        <ErrorMessage
                            errors={errors}
                            name='password'
                            message={passwordErrorMessage}
                        />
                    </span>
                    <Button
                        type='submit'
                        className='btn-protrade btn-label-icon mt-3'
                        onClick={handleSubmit(onSubmit)}
                    >
                        <span>Sign In</span>
                        <BackArrowIcon />
                    </Button>
                    <div className='forgot-password-text'>
                        <span onClick={() => navigate('/auth/reset-password')}>
                            Forgot Password?
                        </span>
                    </div>
                    {/* <div className='separator'>
                        <hr className='line' />
                        <span>Or</span>
                        <hr className='line' />
                    </div>
                    <div
                        className='google-login-button mt-3 mb-5'
                        onClick={() => googleLogin()}
                    >
                        <GoogleIcon />
                        <span>Log in with Google</span>
                    </div> */}
                </form>
            </div>
            {isOpenVerifyModal && <EmailVerfiy navigateUrl={'/auth/signin'} />}
        </div>
    )
}

export default LogIn
