import React, { useEffect, useState } from 'react'
import { Card } from 'components'
import { SelectStyles } from 'components/CustomSelect/SelectStyles'
import { Table } from 'react-bootstrap'
import Select from 'react-select'
import axiosInstance from 'utils/axiosConfig'
import { getPositiveNumber } from 'utils/getPositiveNumber'

function StockTable() {
    const [selectedExchange, setSelectedExchange] = useState(null)
    const [exchangeOpt, setExchangeOpt] = useState([])
    const [stockExchangeLoader, setStockExchangeLoader] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [topTenStockData, setTopTenStockData] = useState(null)
    const [exchangeTextColors, setExchangeTextColors] = useState({})

    const onFetchTopTenStockList = async (id) => {
        setIsLoading(true)
        try {
            let url =
                id === 'all'
                    ? '/portfolio/top_one_year_historical/'
                    : `/portfolio/top_one_year_historical/?exchange_id=${id}`
            axiosInstance
                .get(url)
                .then((res) => {
                    setIsLoading(false)
                    if (res?.data?.data) {
                        setTopTenStockData(res.data?.data)
                    } else {
                        setTopTenStockData([])
                    }
                })
                .catch((er) => {
                    setTopTenStockData([])
                    console.log('err', er)
                    setIsLoading(false)
                })
        } catch (er) {
            console.log(er)
            setIsLoading(false)
        }
    }

    const fetchExchangeData = async () => {
        try {
            const res = await axiosInstance.get('/portfolio/exchange_list/')
            let opt = [{ label: 'All Exchange Symbol', value: 'all' }]
            if (res?.data?.data) {
                res?.data?.data?.length > 0 &&
                    res?.data?.data?.map((item) =>
                        opt.push({
                            label: `${item?.code} (${item?.name})`,
                            value: item?.id,
                            ...item,
                        })
                    )
            }
            generateExchangeTextColors(opt)
            setSelectedExchange(opt[0])
            onFetchTopTenStockList(opt[0]?.value)
            setExchangeOpt(opt)
            setStockExchangeLoader(false)
        } catch (err) {
            setStockExchangeLoader(false)
            setIsLoading(false)
            console.log('err', err)
        }
    }

    const onChangeExchange = (data) => {
        setSelectedExchange(data)
        onFetchTopTenStockList(data.value)
    }
    useEffect(() => {
        setStockExchangeLoader(true)
        setIsLoading(true)
        fetchExchangeData()
        return () => {}
    }, [])

    const generateExchangeTextColors = (data) => {
        const uniqueExchangeCodes = data
            .filter((opt) => opt.value !== 'all')
            .map((opt) => opt.code)
        const colors = {}
        uniqueExchangeCodes.forEach((code) => {
            let randomColor
            let brightness
            do {
                randomColor =
                    '#' + Math.floor(Math.random() * 16777215).toString(16)
                const rgb = parseInt(randomColor.substring(1), 16)
                const r = (rgb >> 16) & 0xff
                const g = (rgb >> 8) & 0xff
                const b = (rgb >> 0) & 0xff
                brightness = (r * 299 + g * 587 + b * 114) / 1000
            } while (brightness > 150)
            colors[code] = randomColor
        })
        setExchangeTextColors(colors)
    }

    const onStocksNameGenerate = (item) => {
        return (
            <div>
                <span>{item?.stock_symbol}</span>
                <span>
                    {' '}
                    ({item?.company_name},{' '}
                    <span
                        style={{
                            color: exchangeTextColors[item.exchange_code],
                            fontWeight: '700',
                        }}
                    >
                        {item?.exchange_code}
                    </span>
                    )
                </span>
            </div>
        )
    }

    return (
        <Card>
            <div className='landing-stock-table mb-3'>
                <Select
                    options={exchangeOpt}
                    placeholder={'Select exchanges symbol'}
                    onChange={onChangeExchange}
                    value={selectedExchange}
                    styles={SelectStyles}
                    noOptionsMessage={() => 'No exchanges symbol'}
                    isLoading={stockExchangeLoader}
                />
            </div>
            <Table
                responsive
                hover={!isLoading}
                className='home-stock-table skeleton-table-loader mb-0'
            >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Stocks</th>
                        <th>PNL</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <>
                            {Array(5)
                                .fill(null)
                                .map((_, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className='loading'>
                                                <div className='bar'></div>
                                            </td>
                                            <td className='loading'>
                                                <div className='bar'></div>
                                            </td>
                                            <td className='loading'>
                                                <div className='bar'></div>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </>
                    ) : (
                        <>
                            {topTenStockData && topTenStockData.length > 0 ? (
                                topTenStockData.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td className='stock-name-text'>
                                                {onStocksNameGenerate(item)}
                                            </td>
                                            <td>
                                                <span
                                                    className={`${
                                                        getPositiveNumber(
                                                            item?.one_year_historical_gain ||
                                                                0
                                                        )
                                                            ? 'negative-text'
                                                            : 'positive-text'
                                                    }`}
                                                >
                                                    {Math.floor(
                                                        item?.one_year_historical_gain ||
                                                            0
                                                    )}
                                                    %
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={3}> Not Found Stock</td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
            </Table>
        </Card>
    )
}

export default StockTable
