import React from 'react'
import Select from 'react-select'
import './customSelect.css'
import { SelectStyles } from './SelectStyles'

function CustomSelect({ options, label, required, className, ...other }) {
    return (
        <div>
            {label && (
                <label className='select-label'>
                    {label}
                    {required && (
                        <span className='select-required text-danger'>*</span>
                    )}
                </label>
            )}
            <Select
                options={options}
                className={className}
                styles={SelectStyles}
                {...other}
            />
        </div>
    )
}

export default CustomSelect
