import { BackArrowIcon } from 'assets'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
    getSubscriptionPalAction,
    loginUserAction,
    socialLoginAction,
} from 'store/action/AuthRedux'
import { Loader } from 'components'
import { useNavigate } from 'react-router-dom'
import { convertObjectToFormData } from 'utils/convertFormData'
import { proTradePlus, subscriptionPlanConst } from 'constant/const'
import axiosInstance from 'utils/axiosConfig'

function SubscriptionPlan() {
    const authData = useSelector((state) => state.auth)
    const { loading, isLogin, planArr, user } = authData
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSubscriptionPalAction())
        return () => {}
    }, [])

    useEffect(() => {
        if (isLogin) {
            navigate('/protrade')
        }
        return () => {}
    }, [isLogin])

    useEffect(() => {
        if (
            user &&
            (user?.subscription_plan === null || !user?.payment_status)
        ) {
            navigate('/auth/subscription-plan')
        }
        return () => {}
    }, [user])

    useEffect(() => {
        let getUSer = localStorage.getItem(proTradePlus)
        if (getUSer === null) {
            navigate('/auth/signin')
        }
        return () => {}
    }, [])

    return (
        <div className='auth-section'>
            {loading && <Loader />}
            <div className='auth-form-section subscription-div'>
                <h1 className='auth-title'>
                    choose subscription plan
                    <span className='text-green'>
                        PROTRADE<sup>+</sup>
                    </span>
                </h1>
                <p className='auth-sub-title'>
                    We offer great price plan for the application
                </p>
                <div className='subscription-wrap mt-2'>
                    {planArr &&
                        planArr?.length > 0 &&
                        planArr?.map((item, i) => {
                            return (
                                <SubscriptionPlanCard
                                    subscriptionPlanInfo={item}
                                    key={i}
                                    user={user}
                                />
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPlan

const SubscriptionPlanCard = ({ subscriptionPlanInfo, user }) => {
    const { currency, description, plan, price } = subscriptionPlanInfo
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const freePlanSelect = () => {
        if (user?.social_id) {
            let formData = convertObjectToFormData({
                plan: plan.toUpperCase(),
                social_id: user?.social_id,
                username: user?.username,
            })
            dispatch(socialLoginAction(formData))
        } else {
            let formData = convertObjectToFormData({
                plan: plan.toUpperCase(),
                password: user?.password,
                username: user?.username,
            })
            dispatch(loginUserAction(formData))
        }
    }

    const handleClick = async () => {
        setIsLoading(true)
        try {
            let formData = convertObjectToFormData({
                plan: plan.toUpperCase(),
                email: user?.email,
                type: 'new',
            })
            const response = await axiosInstance.post(
                'subscription/create_session/',
                formData
            )
            if (response.data && response.data?.data?.url) {
                window.location.href = response.data?.data?.url
            }
            setIsLoading(false)
        } catch (er) {
            console.log('err', er)
            setIsLoading(false)
        }
    }

    return (
        <div className='subscription-section'>
            {isLoading && <Loader />}
            <div className='price-section'>
                <h3 className='price-lable'>{plan.toLowerCase()}</h3>
                <div className='d-flex align-items-baseline  amount-wrap'>
                    <h2>${price}</h2>
                    <sub>/{currency}</sub>
                </div>
            </div>
            <hr />
            <div
                className='description-info'
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            />
            {plan === subscriptionPlanConst.FREE ? (
                <Button
                    onClick={freePlanSelect}
                    className='btn-protrade btn-label-icon btn-subscription p-3 mt-3'
                >
                    <span>Choose plan</span>
                    <BackArrowIcon />
                </Button>
            ) : (
                <div className='custom-stripe-btn'>
                    <Button
                        onClick={handleClick}
                        className='btn-protrade btn-label-icon btn-subscription p-3 mt-3'
                    >
                        <span>Choose plan</span>
                        <BackArrowIcon />
                    </Button>
                </div>
            )}
        </div>
    )
}
