import { IntroVideo } from 'assets'
import React from 'react'
import { Button } from 'react-bootstrap'

function WhyProtradePlus() {
    return (
        <div className='why-protradePlus-section'>
            <h1 className='heading-title'>Why Protrade+?</h1>
            <div className='row'>
                <div className='col-12 col-lg-6'>
                    <ul className='why-protradePlus-lits'>
                        <li>
                            <b>Manual Trading:</b> Customize your watchlist and
                            receive real- time signals to execute trades
                            manually.
                        </li>
                        <li>
                            <b>Autonomous Trading:</b> Link your Interactive
                            Broker account for hands-free, automated trading
                            operations.
                        </li>
                        <li>
                            <b>Precision:</b> Trust in the accuracy Of our
                            algorithm to navigate the markets.
                        </li>
                        <li>
                            <b>User-Friendly:</b> Regardless of your experience
                            level, Protrade+ is designed for simplicity and
                            effectiveness.
                        </li>
                    </ul>
                    <Button
                        className='btn btn-get-started mt-4 '
                        onClick={() => window.open('/auth/signup', '_blank')}
                    >
                        Sign Up Now
                    </Button>
                </div>
                <div className='col-12 col-lg-6 m-auto'>
                    <video controls className='why-protrade-svg-icon'>
                        <source src={IntroVideo} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    )
}

export default WhyProtradePlus
