import { useOutsideClick } from 'Hooks'
import { LendingLogoImage } from 'assets'
import ContactUsModal from 'pages/Home/components/ContactUsModal'
import React, { useRef, useState } from 'react'
import { Button, Container, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Navbars() {
    const [expanded, setExpanded] = useState(false)
    const navbarRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const onClosModal = () => {
        setIsOpen(false)
    }
    // const location = useLocation()
    // const { pathname } = location

    const handleNavbarToggle = () => {
        setExpanded(!expanded)
    }

    const handleLinkClick = () => {
        setExpanded(false)
    }

    useOutsideClick(navbarRef, () => {
        setExpanded(false)
    })

    // const isActive = (url) => {
    //     return pathname === url ? 'active' : ''
    // }

    return (
        <Navbar
            expand='xl'
            className={`nav-bar-header fixed-top`}
            expanded={expanded}
            ref={navbarRef}
        >
            <Container>
                <div className='navbar-logo navbar'>
                    <Link to={'/'}>
                        <img
                            src={LendingLogoImage}
                            alt='protrade+'
                            style={{ maxWidth: '100%' }}
                            onClick={handleLinkClick}
                        />
                    </Link>
                </div>
                <Navbar.Toggle onClick={handleNavbarToggle} />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='ml-auto navbar-manu-wrap landing-page-nav'>
                        {/* <Link
                            to={'/how-it-works'}
                            className={`nav-link ${isActive('/how-it-works')}`}
                            onClick={handleLinkClick}
                            rel='canonical'
                        >
                            How It Works
                        </Link>
                        <DropDownMenuBar
                            handleLinkClick={handleLinkClick}
                            isActive={isActive}
                            dropDownName="Invest"
                            item={[
                                {
                                    path: "/invest/self-managed-signals",
                                    name: "Self-Managed Investing",
                                },
                                {
                                    path: "/invest/autonomous-trading",
                                    name: "Autonomous Trading",
                                }
                            ]}
                        />

                        <DropDownMenuBar
                            dropDownName="Resources"
                            handleLinkClick={handleLinkClick}
                            isActive={isActive}
                            item={[
                                {
                                    path: "/resources/blogs",
                                    name: "Blogs",
                                },
                                {
                                    path: "/resources/community",
                                    name: "Community",
                                }
                            ]}
                        />

                        <Link
                            to={'/pricing'}
                            className={`nav-link ${isActive('/pricing')}`}
                            onClick={handleLinkClick}
                        >
                            Pricing
                        </Link>
                        <Link
                            to={'/about'}
                            className={`nav-link ${isActive('/about')}`}
                            onClick={handleLinkClick}
                        >
                            About
                        </Link> */}
                        <div className='nav-group-login'>

                            <Button
                                className='btn btn-get-started'
                                onClick={() =>
                                    window.open(
                                        '/auth/signUp',
                                        '_blank'
                                    )
                                }
                            >
                                Get Started
                            </Button>
                            <Link
                                to={
                                    '/auth/signin'
                                }
                                className={`nav-link`}
                                onClick={handleLinkClick}
                                target='_blank'
                            >
                                Login
                            </Link>
                            <Link
                                to={"#"}
                                className={`nav-link`}
                                onClick={() => {
                                    handleLinkClick()
                                    setIsOpen(true)
                                }}
                            >
                                Contact Us
                            </Link>
                        </div>
                    </Nav>
                </Navbar.Collapse>
                <ContactUsModal isOpen={isOpen} onClosModal={onClosModal} />
            </Container>
        </Navbar>
    )
}

export default Navbars

const DropDownMenuBar = ({ handleLinkClick, isActive, dropDownName, item }) => {
    const [showMenuDropDown, setShowMenuDropDown] = useState(false)

    return (
        <NavDropdown
            title={dropDownName}
            onMouseEnter={() => setShowMenuDropDown(true)}
            onMouseLeave={() => setShowMenuDropDown(false)}
            show={showMenuDropDown}
        >
            {item &&
                item.length > 0 &&
                item.map((i, index) => {
                    return (
                        <NavDropdown.Item
                            className={`dropdown-nav-link nav-link ${isActive(
                                i.path
                            )}`}
                            key={index}
                        >
                            <Link
                                to={i.path}
                                className={`nav-link `}
                                onClick={handleLinkClick}
                            >
                                {i.name}
                            </Link>
                        </NavDropdown.Item>
                    )
                })}
        </NavDropdown>
    )
}
