export const SelectStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        width: '100%',
        fontFamily: 'Avenir LT Pro',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '16.8px',
        height: '50px',
        borderRadius: '10px',
        border: state.isFocused
            ? '2px solid #B9A978 !important'
            : '1px solid #DFEAF2',
        boxShadow: '#B9A978',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? '#0E1C44'
            : provided.backgroundColor,
        color: state.isSelected ? 'white' : provided.color,
        textTransform: 'math-auto',
        fontFamily: 'Avenir LT Pro',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '16.8px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    singleValue: (provided) => ({
        ...provided,
        // color: '#718EBF !important;',
        fontFamily: 'Avenir LT Pro',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '16.8px',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#718EBF !important;',
        fontFamily: 'Avenir LT Pro',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '16.8px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: '9',
    }),
}
