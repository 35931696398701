export const convertObjectToFormData = (
    object,
    formData = new FormData(),
    parentKey = ''
) => {
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key]
            const fullKey = parentKey ? `${parentKey}[${key}]` : key
            if (
                typeof value === 'object' &&
                !Array.isArray(value) &&
                !(value instanceof File)
            ) {
                convertObjectToFormData(value, formData, fullKey)
            } else {
                formData.append(fullKey, value)
            }
        }
    }
    return formData
}
