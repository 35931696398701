import { AutonomousTradingImage, RealTimeNotificationsImage, SelfDirectedImage } from 'assets'
import React from 'react'

function LongTermSection() {
    return (
        <div className='long-term-section'>
            <div className='long-term-section-wrap'>
                <h1 className="heading-title text-center">Why do 90% Traders lose in the Long Term?</h1>
                <div className='row mt-2 '>
                    <div className='col-12 col-sm-6 col-lg-4'>
                        <div className='long-term-wrap'>
                            <img src={SelfDirectedImage} alt='SelfDirectedImage' />
                            <span>Lack of relevant information</span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-lg-4'>
                        <div className='long-term-wrap'>
                            <img src={AutonomousTradingImage} alt='SelfDirectedImage' />
                            <span>Emotional Trading</span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-lg-4'>
                        <div className='long-term-wrap'>
                            <img src={RealTimeNotificationsImage} alt='SelfDirectedImage' />
                            <span>Insufficient Risk Management</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LongTermSection