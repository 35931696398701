import React, { useEffect, useState } from 'react'
import '../auth.css'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { BackArrowIcon, MessageIcon, ResetPasswordIcon } from 'assets'
import { useForm } from 'react-hook-form'
import { EmailRegx } from 'constant/const'
import { useDispatch, useSelector } from 'react-redux'
import { convertObjectToFormData } from 'utils/convertFormData'
import { passwordResetEmailAction } from 'store/action/AuthRedux'
import { Loader } from 'components'
import EmailVerfiy from '../components/EmailVerfiy'

function ResetPassword() {
    const navigate = useNavigate()
    const [isOpenVerifyModal, setIsOpenVerifyModal] = useState(false)
    const authData = useSelector((state) => state.auth)
    const { resendPwdLink, loading, resetPwdEmailVerfiy } = authData
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm()
    const dispatch = useDispatch()

    useEffect(() => {
        if (resendPwdLink) {
            reset()
        }
        return () => {}
    }, [resendPwdLink])

    const onSubmit = (data) => {
        let formData = convertObjectToFormData(data)
        dispatch(passwordResetEmailAction(formData))
    }

    useEffect(() => {
        if (!resetPwdEmailVerfiy) {
            setIsOpenVerifyModal(true)
        }else{
            setIsOpenVerifyModal(false)
        }
        return () => {}
    }, [resetPwdEmailVerfiy])

    return (
        <div className='auth-section'>
            {loading && <Loader />}
            <div className='navigate-auth-link'>
                <p>
                    <b onClick={() => navigate('/auth/signin')}>
                        BACK TO SIGN IN
                    </b>
                </p>
            </div>
            <div className='auth-form-section'>
                <ResetPasswordIcon />
                <h1 className='auth-title mt-4'>
                    Reset password{' '}
                    <span className='text-green'>
                        PROTRADE<sup>+</sup>
                    </span>
                </h1>
                <p className='auth-sub-title reset-info'>
                    Forgot your password? Enter your e-mail address below, and
                    we'll send you an e-mail fallowing you to reset it.
                </p>
                <form>
                    <div className='input_container'>
                        <MessageIcon className='icon' />
                        <input
                            placeholder='Please enter email'
                            title='Inpit title'
                            name='input-name'
                            type='email'
                            className={`input_field form-control ${
                                errors?.email ? 'error' : ''
                            }`}
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: EmailRegx,
                                    message: '',
                                },
                            })}
                        />
                    </div>

                    <Button
                        type='submit'
                        className='btn-protrade btn-label-icon mt-3 reset-btn'
                        onClick={handleSubmit(onSubmit)}
                    >
                        <span>Send Reset Password</span>
                        <BackArrowIcon />
                    </Button>
                </form>
                <p className='other-info-reset-password mt-4'>
                    Please contact us if you have any trouble resetting your
                    password.
                </p>
            </div>
            {isOpenVerifyModal && <EmailVerfiy navigateUrl={'/auth/reset-password'} />}
        </div>
    )
}

export default ResetPassword
