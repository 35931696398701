import swal from 'sweetalert'

export const customSweetalert = (msg, button, title = '') => {
    return swal({
        title: title,
        text: msg,
        icon: 'warning',
        buttons: button ? button : ['Close', 'Okay'],
    })
}
