import React, { useEffect, useState } from 'react'
import '../auth.css'
import {
    BackArrowIcon,
    GoogleIcon,
    MessageIcon,
    PassworHidedIcon,
    PasswordIcon,
    PasswordShowIcon,
    ProfileIcon,
} from 'assets'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { useForm, useWatch } from 'react-hook-form'
import { EmailRegx, UserNameRegx, passwordErrorMessage, userNameErrroMessage } from 'constant/const'
import { useDispatch, useSelector } from 'react-redux'
import { resetUserInfo, signupUserAction } from 'store/action/AuthRedux'
import { convertObjectToFormData } from 'utils/convertFormData'
import EmailVerfiy from '../components/EmailVerfiy'
import { Loader } from 'components'
import { useSocialLoginHook } from 'Hooks'
import { ErrorMessage } from '@hookform/error-message'
import history from 'history/browser'

function SignUp() {
    const [showPassword, setShowPassword] = useState(false)
    const authData = useSelector((state) => state.auth)
    const { user, loading } = authData
    const navigate = useNavigate()
    const [isOpenVerifyModal, setIsOpenVerifyModal] = useState(false)
    const [userNameForm, setUserNameForm] = useState('')
    const dispatch = useDispatch()
    const { googleLogin } = useSocialLoginHook()

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        clearErrors,
        setError
    } = useForm()

    const emailWatch = useWatch({
        control,
        name: 'email',
    })

    useEffect(() => {
        if (userNameForm === '') {
            if (emailWatch) {
                let newUserName = emailWatch.split('@')[0]
                setValue('username', newUserName?.toLowerCase())
                // Remove regx for username
                // if (UserNameRegx.test(newUserName?.toLowerCase())) {
                //     clearErrors('username')
                // } else {
                //     setError('username', { type: 'required' })
                // }
            } else {
                setValue('username', '')
            }
        }

        return () => { }
    }, [emailWatch, userNameForm])

    const onSubmit = (data) => {
        let formData = convertObjectToFormData(data)
        dispatch(signupUserAction({ data: data, formData: formData }))
    }

    useEffect(() => {
        if (user) {
            setIsOpenVerifyModal(true)
        }
        return () => { }
    }, [user])

    useEffect(() => {
        let unlisten = history.listen(({ location, action }) => {
            dispatch(resetUserInfo())
        })
        return () => {
            unlisten()
        }
    }, [])

    return (
        <div className='auth-section'>
            {loading && <Loader />}
            <div className='navigate-auth-link'>
                <p>
                    Already have an account?
                    <b onClick={() => navigate('/auth/signin')}>
                        {' '}
                        GO TO SIGN IN NOW
                    </b>
                </p>
            </div>
            <div className='auth-form-section'>
                <h1 className='auth-title'>
                    create an account
                    <span className='text-green'>
                        {' '}
                        PROTRADE<sup>+</sup>
                    </span>
                </h1>
                <p className='auth-sub-title'>sign up and join the protrade+</p>
                <form>
                    <div className='input_container'>
                        <MessageIcon className='icon' />
                        <input
                            placeholder='Please enter email'
                            title='Inpit title'
                            name='input-name'
                            type='email'
                            className={`input_field form-control ${errors?.email ? 'error' : ''
                                }`}
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: EmailRegx,
                                    message: '',
                                },
                            })}
                        />
                    </div>
                    <span className='text-danger mt-2 error-message'>
                        <ErrorMessage
                            errors={errors}
                            name='email'
                            message='Please enter the email'
                        />
                    </span>
                    <div className='input_container mt-3'>
                        <ProfileIcon className='icon' />
                        <input
                            placeholder='Please enter username'
                            type='text'
                            className={`input_field form-control ${errors?.username ? 'error' : ''
                                }`}
                            {...register('username', {
                                required: true,
                                minLength: 3,
                                validate: (value) => {
                                    return !!value.trim()
                                },
                                // pattern: {
                                //     value: UserNameRegx,
                                //     message: '',
                                // },
                                onChange: (e) => {
                                    setUserNameForm(e.target.value?.toLowerCase())
                                    setValue('username', e.target.value?.toLowerCase())
                                },
                            })}
                        />
                    </div>
                    <span className='text-danger mt-2 error-message'>
                        <ErrorMessage
                            errors={errors}
                            name='username'
                            message={userNameErrroMessage}
                        />
                    </span>
                    <div className='input_container mt-3'>
                        <PasswordIcon className='icon' />
                        <input
                            placeholder='Please enter the password'
                            type={!showPassword ? 'password' : 'text'}
                            className={`input_field form-control ${errors?.password ? 'error' : ''
                                }`}
                            {...register('password', {
                                required: true,
                                minLength: 8,
                                validate: (value) => {
                                    return !!value.trim()
                                },
                            })}
                        />
                        {!showPassword ? (
                            <PassworHidedIcon
                                className='icon-rigth'
                                onClick={() => setShowPassword(true)}
                            />
                        ) : (
                            <PasswordShowIcon
                                className='icon-rigth'
                                onClick={() => setShowPassword(false)}
                            />
                        )}
                    </div>
                    <span className='text-danger mt-2 error-message'>
                        <ErrorMessage
                            errors={errors}
                            name='password'
                            message={passwordErrorMessage}
                        />
                    </span>
                    <Button
                        type='submit'
                        className='btn-protrade btn-label-icon mt-3'
                        onClick={handleSubmit(onSubmit)}
                    >
                        <span>Sign Up</span>
                        <BackArrowIcon />
                    </Button>

                    {/* <div className='separator'>
                        <hr className='line' />
                        <span>Or</span>
                        <hr className='line' />
                    </div>
                    <div
                        className='google-login-button mt-3 mb-5'
                        onClick={() => googleLogin()}
                    >
                        <GoogleIcon />
                        <span>Continue with Google</span>
                    </div> */}
                </form>
            </div>
            {isOpenVerifyModal && (
                <EmailVerfiy navigateUrl={'/auth/subscription-plan'} />
            )}
        </div>
    )
}

export default SignUp
