import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { errorMessage, proTradePlus } from 'constant/const'
import axiosInstance from 'utils/axiosConfig'
import { toastError, toastSuccess } from 'utils/toaster'

const initialState = {
    user: null,
    loading: false,
    isLogin: false,
    planArr: [],
    resendPwdLink: false,
    passwordReset: false,
    resetPwdEmailVerfiy: true,
}

export const loginUserAction = createAsyncThunk(
    'Auth/loginUserAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/user/account/login/`,
                data
            )
            toastSuccess(response?.data?.message)
            const formDataObj = {}
            data.forEach((value, key) => (formDataObj[key] = value))
            if (!response.data?.data?.active_status) {
                return {
                    active_status: false,
                    isLogin: false,
                    username: formDataObj.username,
                    verifyLoginScreen: true,
                    password: formDataObj.password,
                }
            } else if (
                response.data?.data?.payment_status &&
                response.data?.data?.subscription_plan !== null
            ) {
                return {
                    ...response.data?.data,
                    isLogin: true,
                    verifyLoginScreen: false,
                }
            } else {
                return {
                    ...response.data?.data,
                    isLogin: false,
                    verifyLoginScreen: false,
                    password: formDataObj.password,
                }
            }
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const signupUserAction = createAsyncThunk(
    'Auth/signupUserAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/user/account/signup/`,
                data.formData
            )
            toastSuccess(response?.data?.message)
            return {
                email: data.data.email,
                username: data.data.username,
                password: data.data.password,
                active_status: false,
                verifyLoginScreen: true,
            }
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const verifyUserAction = createAsyncThunk(
    'Auth/verifyUserAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/user/account/verify-otp/`,
                data
            )
            toastSuccess(response?.data?.message)
            return {
                active_status: true,
                verifyLoginScreen: false,
            }
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const passwordResetEmailAction = createAsyncThunk(
    'Auth/passwordResetEmailAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/user/account/password-reset-email/`,
                data
            )
            toastSuccess(response?.data?.message)
            if (!response?.data?.data?.status) {
                return {
                    resetPwdEmailVerfiy: false,
                    username: response?.data?.data?.username,
                }
            } else {
                return { resetPwdEmailVerfiy: true }
            }
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)
export const resendPasswordResetEmailAction = createAsyncThunk(
    'Auth/resendPasswordResetEmailAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/user/account/resend-otp/`,
                data
            )
            toastSuccess(response?.data?.message)
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const forgotpasswordAction = createAsyncThunk(
    'Auth/forgotpasswordAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/user/account/reset-password/`,
                data
            )
            toastSuccess(response?.data?.message)
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const subscriptionAction = createAsyncThunk(
    'Auth/subscriptionAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(`/subscription/create/`, {
                data: data,
            })
            toastSuccess(response?.data?.message)
            return response.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const getSubscriptionPalAction = createAsyncThunk(
    'Auth/getSubscriptionPalAction',
    async () => {
        try {
            const response = await axiosInstance.get(
                `/subscription/plan/get_plan/`
            )
            return response.data?.data
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const logoutUserAction = createAsyncThunk(
    'Auth/logoutUserAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/user/account/logout/`,
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data;' },
                }
            )
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const socialLoginAction = createAsyncThunk(
    'Auth/socialLoginAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/user/account/social_login/`,
                data
            )
            toastSuccess(response?.data?.message)
            const formDataObj = {}
            data.forEach((value, key) => (formDataObj[key] = value))
            if (
                response.data?.data?.payment_status &&
                response.data?.data?.subscription_plan !== null
            ) {
                return {
                    ...response.data?.data,
                    isLogin: true,
                    verifyLoginScreen: false,
                }
            } else {
                return {
                    ...response.data?.data,
                    isLogin: false,
                    verifyLoginScreen: false,
                }
            }
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

export const successPaymentAction = createAsyncThunk(
    'Auth/successPaymentAction',
    async (data) => {
        try {
            const response = await axiosInstance.post(
                `/subscription/verify_payment/`,
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data;' },
                }
            )
            toastSuccess(response?.data?.message)
            return {
                ...response.data?.data,
                isLogin: true,
            }
        } catch (error) {
            toastError(error?.response?.data?.message || errorMessage)
            throw error.response ? error.response.data : error.message
        }
    }
)

const AuthSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        resetUserInfo: (state) => {
            state.isLogin = false
            state.user = null
        },
        setLoginUserData: (state, action) => {
            state.isLogin = action.payload.isLogin
            state.user = action.payload
            localStorage.setItem(
                proTradePlus,
                JSON.stringify({ ...action.payload })
            )
        },
        logoutAction: (state) => {
            state.user = null
            state.isLogin = false
            localStorage.removeItem(proTradePlus)
            window.location.replace('/auth/signin')
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUserAction.pending, (state) => {
                state.loading = true
                state.user = null
                state.isLogin = false
            })
            .addCase(loginUserAction.fulfilled, (state, action) => {
                state.loading = false
                state.user = action.payload
                state.isLogin = action.payload.isLogin
                localStorage.setItem(
                    proTradePlus,
                    JSON.stringify(action.payload)
                )
            })
            .addCase(loginUserAction.rejected, (state) => {
                state.loading = false
                state.user = null
                state.isLogin = false
            })
            .addCase(signupUserAction.pending, (state) => {
                state.loading = true
                state.user = null
            })
            .addCase(signupUserAction.fulfilled, (state, acton) => {
                state.loading = false
                state.user = acton.payload
            })
            .addCase(signupUserAction.rejected, (state) => {
                state.loading = false
                state.user = null
            })
            .addCase(verifyUserAction.pending, (state) => {
                state.loading = true
            })
            .addCase(verifyUserAction.fulfilled, (state, acton) => {
                state.loading = false
                state.user = { ...state.user, ...acton.payload }
                localStorage.setItem(
                    proTradePlus,
                    JSON.stringify({ ...state.user, ...acton.payload })
                )
                state.resetPwdEmailVerfiy = true
            })
            .addCase(verifyUserAction.rejected, (state) => {
                state.loading = false
            })
            .addCase(passwordResetEmailAction.pending, (state) => {
                state.loading = true
                state.resendPwdLink = false
            })
            .addCase(passwordResetEmailAction.fulfilled, (state, action) => {
                state.loading = false
                state.resendPwdLink = true
                state.resetPwdEmailVerfiy = action.payload.resetPwdEmailVerfiy
                state.user = action.payload?.username
                    ? {
                          ...state.user,
                          username: action.payload?.username,
                      }
                    : null
            })
            .addCase(passwordResetEmailAction.rejected, (state) => {
                state.loading = false
                state.resendPwdLink = false
                state.resetPwdEmailVerfiy = true
            })
            .addCase(forgotpasswordAction.pending, (state) => {
                state.loading = true
                state.passwordReset = false
            })
            .addCase(forgotpasswordAction.fulfilled, (state) => {
                state.loading = false
                state.passwordReset = true
            })
            .addCase(forgotpasswordAction.rejected, (state) => {
                state.loading = false
                state.passwordReset = false
            })
            .addCase(subscriptionAction.pending, (state) => {
                state.loading = true
                state.isLogin = false
            })
            .addCase(subscriptionAction.fulfilled, (state, action) => {
                state.loading = false
                state.user = action.payload
                state.isLogin = true
                localStorage.setItem(
                    proTradePlus,
                    JSON.stringify({ isLogin: true, ...action.payload })
                )
            })
            .addCase(subscriptionAction.rejected, (state) => {
                state.loading = false
                state.isLogin = false
            })
            .addCase(getSubscriptionPalAction.pending, (state) => {
                state.loading = true
                state.planArr = []
            })
            .addCase(getSubscriptionPalAction.fulfilled, (state, action) => {
                state.loading = false
                state.planArr = action.payload
            })
            .addCase(getSubscriptionPalAction.rejected, (state) => {
                state.loading = false
                state.planArr = []
            })
            .addCase(logoutUserAction.pending, (state) => {
                state.loading = true
            })
            .addCase(logoutUserAction.fulfilled, (state) => {
                state.loading = false
                state.user = null
                state.isLogin = false
                localStorage.removeItem(proTradePlus)
                window.location.replace('/auth/signin')
            })
            .addCase(logoutUserAction.rejected, (state) => {
                state.loading = false
                state.user = null
                state.isLogin = false
                localStorage.removeItem(proTradePlus)
                window.location.replace('/auth/signin')
            })
            .addCase(socialLoginAction.pending, (state) => {
                state.loading = true
                state.user = null
                state.isLogin = false
            })
            .addCase(socialLoginAction.fulfilled, (state, action) => {
                state.loading = false
                state.user = action.payload
                state.isLogin = action.payload.isLogin
                localStorage.setItem(
                    proTradePlus,
                    JSON.stringify(action.payload)
                )
            })
            .addCase(socialLoginAction.rejected, (state) => {
                state.loading = false
                state.user = null
                state.isLogin = false
            })
            .addCase(successPaymentAction.pending, (state) => {
                state.loading = true
                state.user = null
                state.isLogin = false
            })
            .addCase(successPaymentAction.fulfilled, (state, action) => {
                state.loading = false
                state.user = action.payload
                state.isLogin = action.payload.isLogin
                localStorage.setItem(
                    proTradePlus,
                    JSON.stringify(action.payload)
                )
            })
            .addCase(successPaymentAction.rejected, (state) => {
                state.loading = false
                state.user = null
                state.isLogin = false
            })
    },
})

export const { setLoginUserData, logoutAction, resetUserInfo } =
    AuthSlice.actions
export default AuthSlice.reducer
