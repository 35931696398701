import './App.css'
import { Toaster } from 'react-hot-toast'
import { Fragment, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logoutAction, setLoginUserData } from 'store/action/AuthRedux'
import { proTradePlus } from 'constant/const'
import { Loader } from 'components'
import Router from 'router'
import 'react-phone-number-input/style.css'
import axiosInstance from 'utils/axiosConfig'
import { jwtTokenVerfiy } from 'utils/jwtTokenVerfiy'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useLocation } from 'react-router-dom'

function App() {
    const dispatch = useDispatch()
    const authData = useSelector((state) => state.auth)
    const { isLogin: loginSate, user } = authData

    const location = useLocation();

    useEffect(() => {
        window.gtag('config', 'G-E3C0X2SKJQ', {
            page_path: location.pathname,
        });
    }, [location]);

    useEffect(() => {
        let user = localStorage.getItem(proTradePlus)
        if (user) {
            let userData = JSON.parse(user)
            dispatch(setLoginUserData(userData))
        }
        return () => { }
    }, [])

    useEffect(() => {
        // global header for Authorization and check or verfiy jwt token
        axiosInstance.interceptors.request.use(
            async (config) => {
                try {
                    const token = user?.access
                    if (token) {
                        config.headers['Authorization'] = `Bearer ${token}`
                    }

                    return config
                } catch (error) {
                    console.error('Error setting Authorization header:', error)
                    return Promise.reject(error)
                }
            },
            (error) => {
                console.error('Request interceptor error:', error)
                return Promise.reject(error)
            }
        )
        axiosInstance.interceptors.response.use(
            async (response) => {
                try {
                    return response
                } catch (error) {
                    console.error('Error setting Authorization:', error)
                    return Promise.reject(error)
                }
            },
            (error) => {
                if (error.response?.data?.ResponseCode === 401) {
                    if (user && user?.access) {
                        let logOutUser = jwtTokenVerfiy(user)
                        if (logOutUser) {
                            setTimeout(() => {
                                dispatch(logoutAction())
                            }, 1000)
                        }
                    }
                }
                console.error('Request interceptor error:', error)
                return Promise.reject(error)
            }
        )

        return () => { }
    }, [user])


    return (
        <Fragment>
            <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}
            >
                <Suspense fallback={<Loader />}>
                    <Toaster
                        position='top-center'
                        containerStyle={{ zIndex: 999999999 }}
                    />
                    <Router />
                </Suspense>
            </GoogleOAuthProvider>
        </Fragment>
    )
}
export default App
