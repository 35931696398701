import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Navbars from './Navbars'
import Footer from './Footer'
import './landingLayout.css'

function LandingLayout() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <div className='landing-layouts'>
            <Navbars />
            <div className='mt-3'>
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}

export default LandingLayout
